import React, { useGlobal, useState } from 'reactn';
import { Container, Text } from 'components';
import { Table, Radio } from 'semantic-ui-react';
import { useUserAddress } from 'eth-hooks';
import { reverse, uniqBy } from 'lodash';
import { useEventListener, useContractLoader } from 'hooks';
import { Link } from 'react-router-dom';

// only works on initial load, and not at all on localhost
// change to force a reload by wrapping all data in the useEffect and auto-trigger refresh
function Listings() {
  const [provider] = useGlobal('provider');
  const [filter, setFilter] = useState(false);
  const userAddress = useUserAddress(provider)
  const contracts = useContractLoader(provider);
  const listings = useEventListener(contracts, 'NFTProtocolDEX', 'ListingCreated', provider, 1)

  const listingTypes = [
    ["721", "721"],
    ["721", "20"]
  ]
  const listingsWithIndex = uniqBy(listings, 'id')
  .filter(listing => filter 
    ? listing.creatorAddress === userAddress  
    : true
  ).map((listing, index) => ({
    ...listing,
    index: index,
  }));
  return (
    <div className="flex align-items-center column p30">
      <Radio toggle onChange={()=>setFilter(!filter)} /> <Text>Show Only Mine</Text>
      {listingsWithIndex.length ? reverse(listingsWithIndex).map(({
        creatorAddress, inputAddress, outputAddress, inputId, outputId, listingType, id,
      }) => (
        <Container key={id} className="mb10 w700">
          <Table definition>
            <Table.Body>
              <Table.Row>
                <Table.Cell>id</Table.Cell>
                <Table.Cell><Link to={`/listing?id=${id}`}>{id}</Link></Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>creator</Table.Cell>
                <Table.Cell>{creatorAddress}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>offering</Table.Cell>
                <Table.Cell>{`${inputAddress}#${inputId}`}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>asking</Table.Cell>
                <Table.Cell>{`${outputAddress}#${outputId}`}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>type</Table.Cell>
                <Table.Cell>{listingTypes[listingType].join(' <> ')}</Table.Cell>
              </Table.Row>
              {/* <Table.Row>
                <Table.Cell>status</Table.Cell>
                <Table.Cell>{isClosed ? 'Closed' : 'Open'}</Table.Cell>
                <Table.Cell>{contracts.NFTProtocolDEX.getListing()}</Table.Cell>
              </Table.Row> */}
            </Table.Body>
          </Table>
        </Container>
      )) : <>{filter && <Text>No items to display from account {userAddress}</Text>}</>}
    </div>
  );
}

export default Listings;
