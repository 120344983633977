import React, { useGlobal } from "reactn";
import { Modal } from "semantic-ui-react";

function GlobalModal() {
  const [modalData, setModalData] = useGlobal("modal");
  const { open, header, content, button1, button2 } = modalData;
  return (
    <Modal
      onClose={() => setModalData({ open: false })}
      onOpen={() => setModalData({ open: true })}
      open={open}
      size="mini"
    >
      {header && <Modal.Header>{modalData.header}</Modal.Header>}
      {content && (
        <Modal.Content>
          <Modal.Description>{content}</Modal.Description>
        </Modal.Content>
      )}
      {(button1 || button2) && (
        <Modal.Actions>
          {button1}
          {button2}
        </Modal.Actions>
      )}
    </Modal>
  );
}

export default GlobalModal;
