const baseVariables = {
  headerHeight: "70px",
  screenMedium: "768px",
};

export const light = {
  ...baseVariables,
  name: "light",
  opposite: "dark",
  text: "black",
  boxShadow: "-5px 5px 40px 0px lightgray",
  background: "#e7ebfb",
  backgroundSecondary: "#f4f6ff",
};

export const dark = {
  ...baseVariables,
  name: "dark",
  opposite: "light",
  text: "white",
  boxShadow: "-5px 5px 40px 0px black",
  background: "#232435",
  backgroundSecondary: "#353b50",
};

export default {
  light,
  medium: {},
  dark 
}
