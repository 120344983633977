import React, { useGlobal, useState, useEffect } from 'reactn'
import { useUserAddress } from 'eth-hooks';
import { useExternalContractLoader } from 'hooks';
import Create from 'views/create'
import { Button, Text } from 'components'
import abi721 from 'static/ERC721.json'
import abi20 from 'static/ERC721.json'
import { toBN, fromWei } from 'services/numbers'
import { isEmpty } from 'lodash'
import { useLocation } from "react-router-dom"

function convertArrayToKeyPair(rawValues){
  const listingTypes = [
    ["721", "721"],
    ["721", "20"]
  ]
  return {
    'closed': rawValues[0],
    'inputAddress': rawValues[1],
    'inputId': rawValues[2].toString(),
    'inputAmount': rawValues[3].toString(),
    'outputAddress': rawValues[4],
    'outputId': rawValues[5].toString(),
    'outputAmount': rawValues[6].toString(),
    'listingType': rawValues[7],
    'whitelist': rawValues[8],
    'creatorAddress': rawValues[9],
    'inputType': listingTypes[rawValues[7]][0],
    'outputType': listingTypes[rawValues[7]][1]
  }
}

function Listing(props) {
  const [listing,setListing] = useState({})
  const [message,setMessage] = useState(null)
  const [fees, setFees] = useState({})
  const [provider] = useGlobal('provider')
  const address = useUserAddress(provider);
  const canCancel = listing.creatorAddress === address
  const { tx, contracts} = props
  const id = new URLSearchParams(useLocation().search).get('id');
  

  useEffect(()=>{
    async function getMetadata() {
      if (id){
        const listing = await contracts.NFTProtocolDEX.getListing(id)
        console.log("🚀 ~ file: listing.js ~ line 48 ~ getMetadata ~ listing", listing)
        const data = convertArrayToKeyPair(listing)
        console.log("🚀 ~ file: listing.js ~ line 50 ~ getMetadata ~ data", data)
        if (listing.closed){
          setMessage('⚠️ listing is closed')
        }
        setListing(data)
      }
    }
    async function getFees() {
      const flatFee = await contracts.NFTProtocolDEX.flatFee()
      const percentFee = await contracts.NFTProtocolDEX.percentFee()
      let calculatedFee
      if (listing.outputQuantity){
        calculatedFee = await contracts.NFTProtocolDEX.calculatePercentFee(listing.outputQuantity)
      }
      setFees({
        flatFee:  toBN(flatFee).toString(), 
        percentFee: toBN(percentFee).toString(),
        calculatedFee: toBN(calculatedFee).toString(),
      })
    }
    if (contracts && isEmpty(fees) && !isEmpty(listing)){ getFees() }
    if (contracts && isEmpty(listing)){ getMetadata() }
  })

  const handleCancelClick = async (e) => {
    function handleCancelTx({transaction}){
      if (transaction.status === 'pending') {
        setMessage('Cancelation mining...');
      } else if (transaction.status === 'confirmed') {
        setMessage('Canceled :)');
      }
    }
    tx(contracts.NFTProtocolDEX.cancelListing(id), handleCancelTx)
  }

  const is721 = listing.outputType === '721'
  const abi = is721 ? abi721 : abi20
  const approvalInt = is721 
    ? listing.outputId 
    : toBN(listing.outputQuantity).add(toBN(fees.calculatedFee)).toString()
  const contract = useExternalContractLoader(provider, listing.outputAddress, abi)

  const handleClick = async (e) => {
    try {
      tx(contract.approve(contracts.NFTProtocolDEX.address, approvalInt),({transaction}) => {
        if (transaction.status === 'pending') {
          setMessage('Pending transaction');
        } else if (transaction.status === 'confirmed') {
          setMessage('Confirm new transaction');
          // because not immediate, quick setTimeout will work for new TX
          // look for ways to remove this -- might be specific to local
          setTimeout(() => {
            tx(contracts.NFTProtocolDEX.fulfill(id, {value: flatFee}), ({transaction}) => {
              if (transaction.status === 'pending') {
                setMessage('Pending transaction');
              } else if (transaction.status === 'confirmed') {
                setMessage('Congrats! You have successfully fulfilled this listing');
              }
            })
          }, 1000);
        }        
      })
    } catch (error) {
      setMessage( error.message )
    }
  }
  const {flatFee, calculatedFee} = fees
  const isFlatFee = listing.outputType === '721' 

  return <div>
    <Create
      readOnly
      message={message}
      button={!listing.isClosed && <>
      <Button
        primary
        disabled={!provider}
        onClick={handleClick}
      >
        Fulfill Listing
      </Button>
      {canCancel && <Button
        secondary 
        onClick={handleCancelClick}
      >
        Cancel Listing
      </Button>}
      </>}
      inputType={listing.inputType}
      outputType={listing.outputType}
      whitelist={listing.whitelist}

      // erc 20
      inputAddress={listing.inputAddress}
      inputQuantity={listing.inputAmount && fromWei(toBN(listing.inputAmount), 'ether')}
      outputAddress={listing.outputAddress}
      outputQuantity={listing.outputAmount && fromWei(toBN(listing.outputAmount), 'ether')}

      // erc 721/115
      inputId={listing.inputId}
      outputId={listing.outputId}
      addon={<>
      <Text>
        fee: {fromWei(toBN(isFlatFee ? flatFee: calculatedFee), 'ether')}
      </Text>
      </>}
    />
    </div>
}


export default Listing
