import React, {
  useGlobal, useEffect, useCallback,
} from 'reactn';
import { useUserAddress } from 'eth-hooks';
import { Header, Modal, Contract, Button } from 'components';
import Create from 'views/create';
import Listing from 'views/listing';
import Listings from 'views/listings';
import Web3EthContract from 'web3-eth-contract';
import { Switch, useLocation, Route } from 'react-router-dom';
import { Web3Provider } from '@ethersproject/providers';
import Web3Modal from 'web3modal';
import WalletConnectProvider from '@walletconnect/web3-provider';
import styled, { ThemeProvider } from 'styled-components';
import { Transactor } from './helpers';
import {
  useGasPrice,
  useContractLoader,
} from './hooks';
import { INFURA_ID, NETWORKS } from './constants';
import './App.css';
import 'antd/dist/antd.css';

// Style
const Background = styled.div`
  background-color: ${(props) => props.theme.background};
  min-height: 100vh;
`;
const AppBody = styled.div`
  padding-top: ${(props) => props.theme.headerHeight};
`;

// Web3 Connect
const web3Modal = new Web3Modal({
  cacheProvider: true, // optional, but useful
  providerOptions: {
    walletconnect: {
      package: WalletConnectProvider, // required
      options: {
        infuraId: INFURA_ID,
      },
    },
  },
});

const logoutOfWeb3Modal = async () => {
  await web3Modal.clearCachedProvider();
  setTimeout(() => {
    window.location.reload();
  }, 1);
};

// window.ethereum replaces legacy window.web3
/* eslint-disable */
window.ethereum &&
  window.ethereum.on("chainChanged", chainId => {
    setTimeout(() => {
      window.location.reload();
    }, 1);
  }) &&
  window.ethereum.on
/* eslint-enable */

const DEBUG = process.env.NODE_ENV === 'development';

// needed for pricing utility, will need eventually for DEX
// const mainnetProvider = new JsonRpcProvider(NETWORKS.mainnet.rpcUrl);
// const localProvider = new JsonRpcProvider(NETWORKS.localhost.rpcUrl);

function App() {
  const [userProvider, setInjectedProvider] = useGlobal('provider');
  const [network, setNetwork] = useGlobal('network');
  const targetNetwork = NETWORKS[network || process.env.REACT_APP_NETWORK || 'localhost'];

  if (DEBUG && userProvider) console.log('🦊 userProvider:', userProvider);
  if (!Web3EthContract._provider && userProvider) {
    Web3EthContract.setProvider(userProvider);
  }
  const [theme] = useGlobal('theme');
  const query = new URLSearchParams(useLocation().search);
  console.log("🚀 ~ file: App.jsx ~ line 81 ~ App ~ query", query)
  // console.log("🚀 ~ file: App.jsx ~ line 81 ~ App ~ query", query)
  // will need soon
  // const price = useExchangePrice(targetNetwork, mainnetProvider);
  const gasPrice = useGasPrice(targetNetwork, 'fast');
  // const userProvider = useUserProvider(userProvider);
  const address = useUserAddress(userProvider);

  const selectedChainId = userProvider && userProvider._network && userProvider._network.chainId;
  if (DEBUG && selectedChainId) console.log('🕵🏻‍♂️ selectedChainId:', selectedChainId);

  // The transactor wraps transactions and provides notificiations
  const tx = Transactor(userProvider, gasPrice);

  // will be handy to have
  // const yourLocalBalance = useBalance(localProvider, address);

  // Just plug in different 🛰 providers to get your balance on different chains:
  // const yourBalance = useBalance(userProvider, address);
  // if (DEBUG) console.log('💵 yourBalance', yourBalance
  // ? formatEther(yourBalance) : '...');

  // Load in your local 📝 contract and read a value from it:
  // should be user provider?  Not sure why default is to use local
  const contracts = useContractLoader(userProvider);
  if (DEBUG && contracts) console.log('📝 contracts', contracts);

  const loadWeb3Modal = useCallback(async () => {
    const provider = await web3Modal.connect();
    setInjectedProvider(new Web3Provider(provider));
    window.ethereum.on('accountsChanged', async () => {
      const newProvider = await web3Modal.connect();
      setInjectedProvider(new Web3Provider(newProvider));
    });
  }, [setInjectedProvider]);

  useEffect(() => {
    if (web3Modal.cachedProvider) {
      loadWeb3Modal();
    }
    if (!network) {
      setNetwork(targetNetwork.name)
    }
  }, [loadWeb3Modal, setNetwork, targetNetwork, network]);

  return (
    <ThemeProvider theme={theme}>
      <Modal />
      <Background>
        <Header 
          address={address} 
          handleLogin={loadWeb3Modal} 
          handleLogout={logoutOfWeb3Modal}
          contracts={contracts} 
          provider={userProvider} 
        />
        <AppBody>
          <Switch>
            <Route path="/listings">
              <Listings tx={tx} />
            </Route>
            <Route path="/listing">
              <Listing tx={tx} contracts={contracts} />
            </Route>
            <Route exact path="/contracts">
              {userProvider
              && (
              <div className="flex justify-content-center">
                <Contract
                  name="Factory_20"
                  signer={userProvider.getSigner()}
                  provider={userProvider}
                  address={address}
                  blockExplorer={targetNetwork.blockExplorer}
                />
                <Contract
                  name="Factory_721"
                  signer={userProvider.getSigner()}
                  provider={userProvider}
                  address={address}
                  blockExplorer={targetNetwork.blockExplorer}
                />
                <Contract
                  name="NFTProtocolDEX"
                  signer={userProvider.getSigner()}
                  provider={userProvider}
                  address={address}
                  blockExplorer={targetNetwork.blockExplorer}
                />
              </div>
              )}
            </Route>
            <Route path="/">
              {userProvider 
              ? <Create
                contracts={contracts}
                tx={tx}
                inputType={query.get('inputType') || '721'}
                outputType={query.get('outputType') || '721'}
                whitelist={query.get('whitelist')}
                // erc 20
                inputAddress={query.get('inputAddress')}
                inputQuantity={query.get('inputQuantity')}
                outputAddress={query.get('outputAddress')}
                outputQuantity={query.get('outputQuantity')}
                // erc 721/115
                inputId={query.get('inputId')}
                outputId={query.get('outputId')}
              />
              :<div className="flexCenter w100p vh100">
                <Button onClick={loadWeb3Modal}>Connect Wallet</Button>
              </div>
            }
            </Route>
          </Switch>
        </AppBody>
      </Background>
    </ThemeProvider>
  );
}

export default App;
