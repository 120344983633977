import React, { useState, useEffect, useGlobal } from 'reactn'
import { Text } from 'components'
import styled from 'styled-components'
import web3 from 'web3'
import ethLogo from 'static/ethLogo.png'
import shortenAddress from 'services/shortenAddress'
import { lowerCase } from 'lodash'
import { useBalance } from 'hooks'
import { Icon } from 'semantic-ui-react'
import uniswap from "@uniswap/default-token-list"

const TextContainer = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 20px;
  background-color: lightgray;
  opacity: .8;
  color: black;
`

const TickerContainer = styled.div`
  background: rgb(238,174,202);
  background: radial-gradient(circle, rgba(238,174,202,1) 0%, rgba(148,187,233,1) 100%);
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  height: 300px;
  width: 300px;
  border-radius: 5px;
  overflow: hidden;
  img { 
    width: 100px; 
    position: relative;
    top: -20px;
  }
`

const Card20 = ({ address }) => {
  const isValidAddress = web3.utils.isAddress(address)
  const [provider] = useGlobal('provider');
  const balance = useBalance(provider, address);
  const [state, setState] = useState({ showLogo: false, _previous: {} })
  const previousAddress = state?._previous?.address

  const onLoadError = (e) => {
    setState({
      ...state,
      balance,
      showLogo:false
    })
  }

  useEffect(() => {
    if(
      isValidAddress &&
      (lowerCase(previousAddress) !== lowerCase(address))
    ) {
      async function getMetadata() {
        const tokenInfo = uniswap.tokens.find(x => x.address.toLowerCase() === address.toLowerCase()) || {}
        setState({
          showLogo: !!tokenInfo.logoURI,
          ...tokenInfo,
          _previous:{ address }
        })
      }
      getMetadata()
      // special case for ETH
    } else if(address === 'ETH'){
      setState({
        showLogo: true,
        symbol: 'ETH',
        logoURI: ethLogo
      })
    }
  }, [address, isValidAddress, previousAddress])

  return <TickerContainer>
    {state.showLogo
      ? <img
        src={state.logoURI}
        alt="logo"
        onError={onLoadError}
      />
      : <Text><Icon size="huge" name='question circle outline' /></Text>}
    <TextContainer>
      {state.symbol
        ? <strong>{state.symbol}</strong>
        : <strong className="mediumFont">Custom: {shortenAddress(address)}</strong>
      }
      {/* <div className="mediumFont">Balance: {state.balance ? millify(web3.utils.fromWei(state?.balance)) : 0.0}</div> */}
    </TextContainer>
  </TickerContainer>
}

export default Card20