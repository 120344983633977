import styled from "styled-components"

export default styled.div`
  background: ${props => props?.theme?.backgroundSecondary};
  color: ${props => props.theme?.colors?.textColor || 'gray'};
  box-shadow: ${props => props.theme?.boxShadow};
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
  box-sizing: border-box;
`