import web3 from 'web3'
const castToArray = (value) =>
  value
    ? value.indexOf(',') > -1
      ? value.split(',')
      : new Array(value)
    : []
  
export default (state) => {
      const pair = state.inputType + state.outputType
      const swap = {}
      if (pair === '721721') {
        swap.method = 'createListing721to721'
        swap.params = [
          state.inputAddress,
          state.inputId,
          state.outputAddress,
          state.outputId,
          castToArray(state.whitelist),
        ]
      } else if (pair === '72120'){
        const quantityInWei = web3.utils.toWei(state.outputQuantity.toString(), 'ether');
        console.log('number in wei:', quantityInWei.toString());
        swap.method = 'createListing721to20'
        swap.params = [
          state.inputAddress,
          state.inputId,
          state.outputAddress,
          quantityInWei,
          castToArray(state.whitelist),
        ]
      }
      return swap
  }
