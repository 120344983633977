import React from "react";
import { Button, Text } from "components";
import styled from "styled-components";
import localStorage from "local-storage"
import { useGlobal } from "reactn";
import logo from "static/logo192.png";
import { Dropdown } from "semantic-ui-react";
import { Link } from "react-router-dom";
import themes from "static/themes";

const { version } = require("../../package.json");

const WalletDisplay = styled.div`
  color: ${props => props.theme.text}; 
  padding: 10px;
  display: inline;
  margin-right: 15px;
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.backgroundSecondary};
`;

const Header = styled.div`
  display: flex;
  padding: 12px;
  position: absolute;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: ${props => props.theme.headerHeight};
  top: 0;
  border-bottom: 1px solid ${({ theme }) => theme.backgroundSecondary};
  box-sizing: border-box;
`;

const Export = props => {
  const { address, handleLogout, handleLogin, contracts } = props;
  const [theme, setTheme] = useGlobal('theme');

  const toggleTheme = () => {
    localStorage.set('theme',theme.opposite)
    setTheme(themes[theme.opposite])
  }

  return (
    <Header>
      <div className="flex align-items-center">
        <Link to="/" className="mr10">
          <img height="35" src={logo} alt="logo" className="mr10" />
        </Link>
        <Link to="/" className="mr10">
          <Text>Create</Text>
        </Link>
        <Link to="/listings" className="mr10">
          <Text>Listings</Text>
        </Link>
        {process.env.NODE_ENV === 'development' && 
          <Link to="/contracts" className="mr10">
            <Text>Contracts</Text>
          </Link>
        }
        <a href="https://staking.page/nft/" className="mr10">
          <Text>Stake</Text>
        </a>
        <a href="https://docs.nft.org" className="mr10">
          <Text>About</Text>
        </a>
      </div>
      <div>
        <>
          {address ? (
            <WalletDisplay>{address}</WalletDisplay>
          ) : (
            <Button primary onClick={handleLogin} color="blue">
              connect wallet
            </Button>
          )}
        </>
        <Dropdown button color="white" className="icon" icon="cog">
          <Dropdown.Menu direction="left">
            <Dropdown.Item text={`${process.env.REACT_APP_NETWORK}: v${version}`} />
            <Dropdown.Item text={`swith to ${theme.opposite} mode`} onClick={toggleTheme} />
            <Dropdown.Item text={`dex address: ${contracts?.NFTProtocolDEX?.address}`} />
            {address && <Dropdown.Item text="logout" onClick={handleLogout} />}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </Header>
  );
};

export default Export;
