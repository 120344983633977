import React, { setGlobal } from 'reactn';
import ReactDOM from 'react-dom';
import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';
import { BrowserRouter as Router } from 'react-router-dom';
import localStorage from "local-storage"
import 'semantic-ui-css/semantic.min.css';
import themes from './static/themes';
import App from './App';
// import AppLegacy from "./_App";

const subgraphUri = 'http://localhost:8000/subgraphs/name/scaffold-eth/your-contract';

const client = new ApolloClient({
  uri: subgraphUri,
  cache: new InMemoryCache(),
});


// outside of react, so must manually read local storage
const localThemeName = localStorage.get('theme') || 'dark';
setGlobal({
  theme: themes[localThemeName],
  modal: {},
});
ReactDOM.render(
  <ApolloProvider client={client}>
    <Router>
      <App subgraphUri={subgraphUri} />
    </Router>
  </ApolloProvider>,
  document.getElementById('root'),
);
