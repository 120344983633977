import React, { useGlobal, useState, useEffect } from 'reactn';
import shortenAddress from 'services/shortenAddress';
import { Container, Input, Card20, Card721, Button, Text, } from 'components';
import supportedTokens from 'static/tokens.json';
import { startCase, toPairs, omit } from 'lodash';
import { Icon, Accordion, Divider, Tab, Form, Dropdown } from 'semantic-ui-react';
import styled from 'styled-components';
import configureSwap from 'services/configureSwapFromState'
import { useExternalContractLoader } from 'hooks';
import abi721 from 'static/ERC721.json'
import abi20 from 'static/ERC721.json'
import { Link } from 'react-router-dom';

const Background = styled.div`
  min-height: ${(props) => `calc(100vh - ${props.theme.headerHeight})`};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  justify-content: center;
`;

const ResponsiveContainer = styled.div`
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (min-width: ${({ theme }) => theme.screenMedium}) {
    flex-direction: row;
  }
`;

const ArrowBlock = styled.div`
  font-size: 28px;
  width: 100px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: rotate(-90deg);
  @media (min-width: ${({ theme }) => theme.screenMedium}) {
    transform: rotate(0deg);
  }
  `;
  
  const defaultOptions = toPairs(supportedTokens['20'].mainnet).map(([key, value]) => ({
    key,
    text: key,
    value,
  }));
  
function Create(props) {
  const [message, setMessage] = useState(props.message);
  const {contracts, tx} = props;
  const [theme] = useGlobal('theme');
  const [provider] = useGlobal('provider');
  const [state, setState] = useState({
    ...props,
    dropdownOptions: defaultOptions,
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (!props.readOnly) {
      // updates URL with state parameters
      const queryString = Object.keys(omit(props, ['tx', 'contracts']))
        .map((key) => {
          let val = '';
          if (state[key]) {
            val = key + '=' + state[key];
          }
          return val;
        })
        .filter((x) => !!x)
        .join('&');
      window.history.replaceState({}, '', '?' + queryString);

      // sets ETH as default if 20 card chosen and no specified address
      // Object.keys(props).forEach((key) => {
      //   if (state[key] === '20') {
      //     const coordinatingAddress = key.replace(/([A-Z])/g, ',$1').split(',')[0] + 'Address';
      //     if (!state[coordinatingAddress]) {
      //       setState({
      //         ...state,
      //         [coordinatingAddress]: 'ETH',
      //       });
      //     }
      //   }
      // });
    } else if (props.readOnly && !state.inputType && props.inputType) {
      setState({
        ...state,
        ...props,
      });
    }
  });

  function updateInput(e, key) {
    setState({
      ...state,
      [key]: e.target.value,
    });
  }

  const is721 = state.inputType === '721'
  const abi = is721 ? abi721 : abi20
  const approvalInt = is721 ? state.inputId : state.inputQuantity
  const contract = useExternalContractLoader(provider, state.inputAddress, abi)

  const handleListingClick = async (e) => {
    // add in TX FEE
    tx(contract.approve(contracts.NFTProtocolDEX.address, approvalInt),async (info) => {
      if (info.transaction.status === 'pending') {
        setMessage(
          'First approve the token send ✅',
        );
      } else if (info.transaction.status === 'confirmed') {
        setTimeout(() => {
        setMessage('Next confirm the transaction to complete your listing ');
        const {method, params} = configureSwap(state)
        tx(
          contracts.NFTProtocolDEX[method](...params),
          async(info)=>{
            if (info.transaction.status === 'pending') {
              setMessage('Pending transaction');
            } else if (info.transaction.status === 'confirmed') {
              const receipt = await provider.getTransactionReceipt(info.hash)
              const id = receipt.logs.slice(-1)[0].topics[2]
              setMessage(<>Listing created: <Link to={`/listing/${id}`}>{`${window.location.host}/listing/${id}`}</Link></>);
            }
          },
        );
      }, 3000);
      }
    })
  };

  const handleAccordianClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = state;
    const newIndex = activeIndex === index ? -1 : index;
    setState({
      ...state,
      activeIndex: newIndex,
    });
  };

  const ConnectedInput = (_key) => {
    const [key, inputType] = _key.includes('-') ? _key.split('-') : [_key, 'input'];
    let optionsToAdd = [];
    const supportedMatch = state.dropdownOptions.find((x) => x.value === state[key]);
    if (inputType === 'dropdown' && !supportedMatch && state[key]) {
      // check if need to add address passed in through props to default options
      optionsToAdd = [
        {
          text: shortenAddress(state[key]),
          value: state[key],
        },
      ];
    }
    return (
      <Form className="mt10 w100p" key={key}>
        <Form.Field>
          <label>
            <Text>{startCase(key)}</Text>
          </label>
          {inputType === 'dropdown' ? (
            <Dropdown
              search
              disabled={props.readOnly}
              selection
              allowAdditions
              onChange={(_, data) => updateInput({ target: { value: data.value } }, key)}
              options={[...optionsToAdd, ...state.dropdownOptions]}
              value={state[key]}
            />
          ) : (
            <Input disabled={props.readOnly} fluid placeholder={key} value={state[key] || ''} onChange={(e) => updateInput(e, key)} />
          )}
        </Form.Field>
      </Form>
    );
  };

  const paneTypes = ['721', '20'];
  const onSwitchType = (data, prefix) => {
    setState({
      ...omit(state, [prefix + 'Address', prefix + 'Quantity']),
      [prefix + 'Type']: data.panes[data.activeIndex].menuItem,
    });
  };
  const renderPanes = (prefix) => [
    {
      menuItem: paneTypes[0],
      render: () => (
        <>
          <Card721 address={state[`${prefix}Address`]} id={state[`${prefix}Id`]} />
          {[`${prefix}Address`, `${prefix}Id`].map(ConnectedInput)}
        </>
      ),
    },
    {
      menuItem: paneTypes[1],
      render: () => (
        <>
          <Card20 address={state[`${prefix}Address`]} />
          {[`${prefix}Address-dropdown`, `${prefix}Quantity`].map(ConnectedInput)}
        </>
      ),
    },
  ];


  const { inputType, outputType } = state;
  const messageToDisplay = message || props.message
  return (
    <Background>
      {provider && (
        <div>
          {messageToDisplay && (
            <Container className="mb20 flex column text-align-center">
              <Text>{messageToDisplay}</Text>
            </Container>
          )}
          <Container className="mb20 flex column">
            <ResponsiveContainer>
              <Tab
                menu={{ secondary: true, inverted: theme.name === 'dark' }}
                panes={renderPanes('input')}
                onTabChange={(e, data) => !props.readOnly && onSwitchType(data, 'input')}
                activeIndex={paneTypes.indexOf(inputType)}
                className="w300"
              />
              <ArrowBlock>
                <Icon className="m0" name="arrows alternate horizontal" />
              </ArrowBlock>
              <Tab
                menu={{ secondary: true, inverted: theme.name === 'dark' }}
                panes={renderPanes('output')}
                onTabChange={(e, data) => !props.readOnly && onSwitchType(data, 'output')}
                activeIndex={paneTypes.indexOf(outputType)}
                className="w300"
              />
            </ResponsiveContainer>
            <Divider className="w100p" />
            <Accordion fluid>
              <Accordion.Title
                active={state.activeIndex === 0}
                index={0}
                onClick={handleAccordianClick}
              >
                <Text>
                  <Icon name="dropdown" />
                  Advanced
                </Text>
              </Accordion.Title>
              <Accordion.Content active={(props.whitelist && props.readOnly) || state.activeIndex === 0} >
                <Form>
                  <Form.Field>
                    <label>
                      <Text>Private Listing</Text>
                    </label>
                    <Input
                      disabled={props.readOnly}
                      fluid
                      placeholder="Allowed Address(es)"
                      value={state.whitelist || ''}
                      onChange={(e) => updateInput(e, 'whitelist')}
                    />
                  </Form.Field>
                </Form>
              </Accordion.Content>
            </Accordion>
          </Container>
          <div className="flex justify-content-center column align-items-center">
            {props.button}
            {!props.readOnly && !props.button && (
            <Button primary disabled={!provider} onClick={handleListingClick}>
              {provider ? 'Create Listing' : 'Link Wallet To Proceed'}
            </Button>
            )}
            {props.addon}
          </div>
        </div>
      )}
    </Background>
  );
}

export default Create;
