import React, { useState, useEffect, useGlobal } from 'reactn';
import styled from 'styled-components';
import { Placeholder } from 'semantic-ui-react';
import Web3 from 'web3';
import { capitalize } from 'lodash';
import { OpenSeaPort, Network } from 'opensea-js';

const TextContainer = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 20px;
  background-color: lightgray;
  opacity: 0.8;
  max-height: 100px;
  color: black;
`;

const NFTCard = ({ address, id }) => {
  const isValidAddress = Web3.utils.isAddress(address);
  const [state, setState] = useState({ _previous: {} });
  const [provider] = useGlobal('provider');
  const seaport = new OpenSeaPort(provider, { 
    networkName: Network[capitalize(process.env.REACT_APP_NETWORK)],
    apiKey: process.env.REACT_APP_OPENSEA_KEY
  });

  useEffect(() => {
    let fetching;
    async function getMetadata() {
      fetching = true;
      try {
        const asset = await seaport.api.getAsset({
          tokenAddress: address,
          tokenId: id,
        });
        fetching = false;
        setState({
          ...asset,
          _previous: { address, id },
        });
      } catch (e) {
        fetching = false;
        // incase a throttle happens after initial load
        if (!state.name) {
          setState({
            _error: e.toString(),
            _previous: { address, id },
          });
        }
      }
    }
    if (!fetching 
      && isValidAddress 
      && id 
      && !state.name
      && (state._previous.address !== address || state._previous.id !== id)) {
      getMetadata();
    }
  });
  const imageUrl = state.image || state.imageUrl;
  return (
    <div className="relative h300 w300 overflow-hidden rounded-corners background-white">
      {imageUrl ? (
        <img className="w300" src={imageUrl} alt="NFT URL" />
      ) : (
        <Placeholder>
          <Placeholder.Image square />
        </Placeholder>
      )}
      <TextContainer>
        {state._error && (
        <div>
          Error loading token:
          {state._error}
        </div>
        )}
        {(state.name || state.description) && (
          <>
            <strong>{state.name}</strong>
          </>
        )}
      </TextContainer>
    </div>
  );
};

export default NFTCard;
